//https://www.codetreasure.com/ck_API/public/api/
//http://127.0.0.1:8000/api/
//http://3.6.19.77/api/
import store from "@/state/store";
import Vue from "vue";
export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/restaurantLogin",
    name: "restaurantLogin",
    component: () => import("../views/pages/account/restaurantLogin"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/corporateLogin",
    name: "corporateLogin",
    component: () => import("../views/pages/account/corporateLogin"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // confirm('Do you want to proceed?')
        // .then(function () {
        //   const authRequiredOnPreviousRoute = routeFrom.matched.some(
        //     (route) => route.push('/login')
        //   )
        //  // Navigate back to previous page, or home as a fallback
        //   next(authRequiredOnPreviousRoute ? {
        //     name: 'home'
        //   } : {
        //     ...routeFrom
        //   })
        // })
        // .catch(function () {
        //     next(false);
        // });
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else if (
          process.env.VUE_APP_DEFAULT_AUTH === "fakebackend"
        )
        {
          Vue.swal({
            title: "Are you sure?",
            text: "You won't be able to access the dashboard !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Logout!"
          }).then((result)=>{
            if(result.isConfirmed){
              store.dispatch('logout')
                  .then(()=>{
                    localStorage.removeItem("vuex");
                  });
            }
          })
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(route =>
          route.push("/login")
        );
        //Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home"
              }
            : {
                ...routeFrom
              }
        );
      }
    }
  },
  {
    path: "/restLogout",
    name: "restLogout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch("authfack/restLogout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(route =>
          route.push("/RestaurantLogin")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home"
              }
            : {
                ...routeFrom
              }
        );
      }
    }
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/index")
  },
  {
    path: "/dashboard/restaurantDashboard",
    name: "restaurantDashboard",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/restaurantDashboard")
  },
  {
    path: "/dashboard/corporateDashboard",
    name: "corporateDashboard",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/corporateDashboard")
  },
  {
    path: "/dashboard/superAdminDashboard",
    name: "superAdminDashboard",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/superAdminDashboard")
  },
  ///////////////// Restaurant Login //////////////
  //  {
  //   path: '/restaurantLogin',
  //   name: 'restaurantLogin',
  //   meta: {
  //     //authRequired: true,
  //   },
  //   component: () => import('../views/pages/account/restaurantLogin'),
  // },
  /////////////// add company //////////////////
  {
    path: "/form/companyForm",
    name: "companyForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/companyForm")
  },
  /////////////// view company //////////////////
  {
    path: "/tables/companyTable",
    name: "companyTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/companyTable")
  },

  ///// add restuarants/////////////////
  {
    path: "/form/restaurantForm/:type",
    name: "addRestaurant",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantForm")
  },
  ///// view restuarants/////
  {
    path: "/tables/restaurantTable",
    name: "restaurantTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/restaurantTable")
  },
  /////////////////Add restaurant branch////////////////

  {
    path: "/form/restaurantBranchForm/:type",
    name: "restaurantBranchForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantBranchForm")
  },
  /////////////////View restaurant branch////////////////

  {
    path: "/tables/restaurantBranchTable",
    name: "restaurantBranchTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/restaurantBranchTable")
  },
  /////////////////Add Corporate ////////////////

  {
    path: "/form/corporateForm/:type",
    name: "corporateForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/corporateForm")
  },
  /////////////////Add Corporate ////////////////

  {
    path: "/tables/corporateTable",
    name: "corporateTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/corporateTable")
  },
  ///////////////// add corporate branch form //////////////
  {
    path: "/form/corporateBranchForm",
    name: "corporateBranchForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/corporateBranchForm")
  },
  ///////////////// view corporate branch datatable //////////////
  {
    path: "/tables/corporateBranchTable",
    name: "corporateBranchTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/corporateBranchTable")
  },

  ///////////////// add restaurant owner form //////////////
  {
    path: "/form/restaurantOwnerForm",
    name: "restaurantOwnerForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantOwnerForm")
  },

  ///////////////// add corporate owner form //////////////
  {
    path: "/form/corporateOwnerForm",
    name: "corporateOwnerForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/corporateOwnerForm")
  },

  ///////////////// add restaurant menu  form //////////////
  {
    path: "/form/restaurantMenuForm/:type",
    name: "restaurantMenuForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantMenuForm")
  },
  /////////////////// edit restaurant menu /////////////////////
  {
    path: "/form/restaurantMenuForm?type=edit",
    name: "editRestaurantMenuForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantMenuForm")
  },
  {
    path: "/tables/restaurantMenuTable",
    name: "restaurantMenuTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/restaurantMenuTable")
  },
  ///////////////// add restaurant menu  form //////////////
  {
    path: "/form/test",
    name: "test",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/test")
  },

  ///////////////// add addons for menu  form //////////////
  {
    path: "/form/addonsForm/:type",
    name: "addonsForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/addonsForm")
  },

  /////////////////// view addonsTables /////////////////
  {
    path: "/tables/addonsTables",
    name: "addonsTables",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/addonsTables")
  },
  /////////////////// add category /////////////////
  {
    path: "/form/categoryForm",
    name: "categoryForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/categoryForm")
  },
  /////////////////// view category /////////////////
  {
    path: "/tables/categoryTable",
    name: "categoryTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/categoryTable")
  },
  /////////////////// manage orders  /////////////////
  {
    path: "/orders/manageOrders",
    name: "manageOrders",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/orders/manageOrders")
  },

  ///////////////////  orders history  /////////////////
  {
    path: "/orders/ordersTable",
    name: "ordersTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/orders/ordersTable")
  },
  //////////////////////restaurantMenuListTable //////////////////////
  {
    path: "/tables/restaurantMenuListTable",
    name: "restaurantMenuListTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/restaurantMenuListTable")
  },

  //////////////////////restaurantMenuListTable //////////////////////
  {
    path: "/profile/restaurantDetails",
    name: "restaurantDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/restaurantDetails")
  },

  //////////////////////register cluster //////////////////////
  {
    path: "/form/clusterForm/:type",
    name: "clusterForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/clusterForm")
  },

  //////// register corporate users ////////////////////
  {
    path: "/form/corporateUsersForm",
    name: "corporateUsersForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/corporateUsersForm")
  },

  //////// Corporate users table ////////////////////
  {
    path: "/tables/usersTable",
    name: "usersTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/usersTable")
  },

  //////// Order Tranactions ////////////////////
  {
    path: "/orders/orderTransactionTable",
    name: "orderTransactionTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/orders/orderTransactionTable")
  },

  //////// Corporate Profile ////////////////////
  {
    path: "/profile/corporateProfile",
    name: "corporateProfile",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/corporateProfile")
  },
  //////// discount form ////////////////////
  {
    path: "/form/discountForm",
    name: "discountForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/discountForm")
  },
  //////// discount view table ////////////////////
  {
    path: "/tables/discountTable",
    name: "discountTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/discountTable")
  },

  //////// Miscellaneous ////////////////////
  {
    path: "/form/miscellaneousForm",
    name: "miscellaneousForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/miscellaneousForm")
  },

  //////// add Principle Form ////////////////////
  {
    path: "/form/principleForm/:type",
    name: "principleForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/principleForm")
  },

  //////////////// principleBranchForm //////////////////

  {
    path: "/form/principleBranchForm/:type",
    name: "principleBranchForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/principleBranchForm")
  },

  //////////////// principle table //////////////////

  {
    path: "/tables/principleTable",
    name: "principleTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/principleTable")
  },

  //////////////// principle branchess table //////////////////

  {
    path: "/tables/principleBranchTable",
    name: "principleBranchTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/principleBranchTable")
  },
  //////////////// principle branchess table //////////////////

  {
    path: "/profile/principleProfile",
    name: "principleProfile",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/principleProfile")
  },

  ///////// addCloudkitchEmployeeForm //////////////////////////////
  {
    path: "/form/cloudkitchEmployeeForm/:type",
    name: "cloudkitchEmployeeForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/cloudkitchEmployeeForm")
  },
  {
    path: "/tables/cloudkitchEmployeeTable",
    name: "cloudkitchEmployeeTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/cloudkitchEmployeeTable")
  },

  {
    path: "/tables/foodCourtTable",
    name: "foodCourtTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/foodCourtTable")
  },

  {
    path: "/form/foodCourtForm/:type",
    name: "foodCourtForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/foodCourtForm")
  },

  ///////////restaurantCategoryForm ///////////

  {
    path: "/form/restaurantCategoryForm",
    name: "restaurantCategoryForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantCategoryForm")
  },
  ///////////restaurantCategoryTable ///////////
  {
    path: "/tables/restaurantCategoryTable",
    name: "restaurantCategoryTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/restaurantCategoryTable")
  },
  ////////////////mainLoginPage ///////////////
  {
    path: "/mainLoginPage",
    name: "mainLoginPage",
    component: () => import("../views/pages/account/mainLoginPage"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },

  ///////////add wallet points form ///////////
  {
    path: "/form/walletForm/",
    name: "walletForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/walletForm")
  },
  ////////foodCourtDetails ////////////////
  {
    path: "/profile/foodCourtDetails/",
    name: "foodCourtDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/foodCourtDetails")
  },
  ///////////////restaurantMenuDetails ////////////////////////
  {
    path: "/profile/restaurantMenuDetails",
    name: "restaurantMenuDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/restaurantMenuDetails")
  },
  /////////////userProfile ////////////////////////
  {
    path: "/profile/userProfile",
    name: "userProfile",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/userProfile")
  },
  ///////////add corporate meal plan form ///////////
  {
    path: "/form/corporateMealPlanForm/:type",
    name: "corporateMealPlanForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/corporateMealPlanForm")
  },

  ///////////////// view corporate meal plan table //////////
  {
    path: "/tables/corporateMealPlanTable/",
    name: "corporateMealPlanTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/corporateMealPlanTable")
  },
  /////////  configure Meal Plan Form ////////////////
  {
    path: "/form/configureMealPlanForm/",
    name: "configureMealPlanForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/configureMealPlanForm")
  },
  ////////////////mainLoginPage ///////////////
  {
    path: "/auth/recoverpwd/:type",
    name: "recoverpwd",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/auth/recoverpwd")
  },

  //////////// create compliance template ///////////
  {
    path: "/form/complianceTemplate/:type",
    name: "complianceTemplate",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/complianceTemplate")
  },

  ///////////////// compliance template table //////////
  {
    path: "/tables/complianceTemplateTable/",
    name: "complianceTemplateTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/complianceTemplateTable")
  },
  ///////////////form/operationalReportForm ///////////
  {
    path: "/form/operationalReportForm/:type",
    name: "operationalReportForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/operationalReportForm")
  },
  ///////////////// compliance template table //////////
  {
    path: "/tables/operationalChecklistTable",
    name: "operationalChecklistTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/operationalChecklistTable")
  },

  ///////////////// pre buy voucher form //////////
  {
    path: "/form/preBuyVoucherForm",
    name: "preBuyVoucherForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/preBuyVoucherForm")
  },

  ///////////////// pre buy voucher tables //////////
  {
    path: "/tables/preBuyVoucherTable",
    name: "preBuyVoucherTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/preBuyVoucherTable")
  },

  ///////////////// pre buy voucher tables //////////
  {
    path: "/tables/operationalReportsForCorporate",
    name: "operationalReportsForCorporate",
    meta: {
      authRequired: true
    },
    component: () =>
      import("../views/pages/tables/operationalReportsForCorporate")
  },

  ///////////////// purchased voucher tables //////////
  {
    path: "/tables/purchasedVouchersTable/:type",
    name: "purchasedVouchersTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/purchasedVouchersTable")
  },

  ///////////////// wallet point settings //////////
  {
    path: "/form/walletPointSettings",
    name: "walletPointSettings",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/walletPointSettings")
  },
  //////////////walletStatisticsTable //////////////////
  {
    path: "/tables/walletStatisticsTable",
    name: "walletStatisticsTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/walletStatisticsTable")
  },

  //////////////foodcourtStatisticsTable //////////////////
  {
    path: "/tables/foodcourtStatisticsTable",
    name: "foodcourtStatisticsTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/foodcourtStatisticsTable")
  },

  ////////////////mealPlanStatistics/////////////////////
  {
    path: "/tables/mealPlanStatisticsTable/:type",
    name: "mealPlanStatisticsTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/mealPlanStatisticsTable")
  },
  /////purchasedVoucherList//////////////////
  {
    path: "/tables/purchasedVoucherPlanTable",
    name: "purchasedVoucherPlanTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/purchasedVoucherPlanTable")
  },

  /////complianceReport //////////////////
  {
    path: "/reports/complianceReport",
    name: "complianceReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/reports/complianceReport")
  },
  /////// complianceReportTable /////////////////
  {
    path: "/reports/complianceReportTable",
    name: "complianceReportTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/reports/complianceReportTable")
  },

  {
    path: "/principleLogin",
    name: "principleLogin",
    component: () => import("../views/pages/account/principleLogin"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },

  /////// principle Corporates /////////////////
  {
    path: "/tables/principleCorporates",
    name: "principleCorporatesTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/principleCorporates")
  },
  /////// principle Restaurants /////////////////
  {
    path: "/tables/principleFoodCourtsTable",
    name: "principleFoodCourtsTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/principleFoodCourtsTable")
  },
  {
    path: "/dashboard/principleDashboard",
    name: "principleDashboard",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/principleDashboard")
  },

  {
    path: "/form/corporateAdminForm/:type",
    name: "corporateAdminForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/corporateAdminForm")
  },{
    path: "/form/principleAdminForm/:type",
    name: "principleAdminForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/principleAdminForm")
  },

  {
    path: "/form/bannerForm",
    name: "bannerForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/bannerForm")
  },

  {
    path: "/profile/bannerDetails",
    name: "bannerDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/bannerDetails")
  },

  {
    path: "/tables/restaurantApprovalTable",
    name: "restaurantApprovalTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/restaurantApprovalTable")
  },

  {
    path: "/tables/corporateApprovalTable",
    name: "corporateApprovalTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/corporateApprovalTable")
  },
  {
    path: "/tables/principleApprovalTable",
    name: "principleApprovalTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/principleApprovalTable")
  },
  {
    path: "/tables/foodMenuApprovalTable",
    name: "foodMenuApprovalTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/foodMenuApprovalTable")
  },

  {
    path: "/tables/salesReport",
    name: "salesReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/salesReport")
  },

  {
    path: "/orders/pendingOrdersTable",
    name: "pendingOrdersTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/orders/pendingOrdersTable")
  },
  {
    path: "/tables/mealPlanFullReport",
    name: "mealPlanFullReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/mealPlanFullReport")
  },
  {
    path: "/tables/productMixReport",
    name: "productMixReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/productMixReport")
  },
  {
    path: "/tables/salesMixReport",
    name: "salesMixReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/salesMixReport")
  },
  {
    path: "/tables/sodexo-reports",
    name: "SodexoReports",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/SodexoReports")
  },
  {
    path: "/tables/qr-sales-reports",
    name: "PayU_QR_SalesReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/PayU_QR_SalesReport")
  },
  {
    path: "/tables/smartworks-sales",
    name: "SmartWorksSales",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/SmartWorksSales")
  },
  {
    path: "/tables/cash-sales-reports",
    name: "CashSalesReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/CashSalesReport")
  },
  {
    path: "/tables/dsr",
    name: "DailySalesReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/dailySalesReport")
  },
  {
    path: "/tables/walletReport",
    name: "walletReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/walletReport")
  },
  {
    path: "/tables/itemWiseReport",
    name: "itemWiseReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/itemWiseReport")
  },

  {
    path: "/tables/dayPartReport",
    name: "dayPartReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/dayPartReport")
  },
  {
    path: "/tables/rejectionReport",
    name: "rejectionReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/rejectionReport")
  },
  {
    path: "/tables/subsidyReport",
    name: "subsidyReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/subsidyReport")
  },
  // {
  //   path: '/form/feedbackTemplate',
  //   name: 'feedbackForm',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/feedbackTemplate')
  // },
  {
    path: "/form/feedbackTemplate/:type",
    name: "feedbackForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/feedbackTemplate")
  },
  {
    path: "/form/feedback-reason-group/",
    name: "feedbackReasonGroup",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/feedbackReasonGroup")
  },
  {
    path: "/table/feedback-reason-group-table/",
    name: "feedbackReasonGroupList",
    meta: {
      authRequired: true
    },
    component: () =>
      import("../views/pages/tables/FeedbackReasonGroupTable.vue")
  },
  {
    path: "/tables/fdbackViewTemplates",
    name: "fdbackViewTemplates",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/fdbackViewTemplates")
  },
  {
    path: "/tables/feedbackRestWiseReport",
    name: "FeedbackRestWiseReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/feedbackRestWiseReport")
  },
  {
    path: "/tables/feedbackMixReport",
    name: "feedbackMixReport",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/feedbackMixReport")
  },
  {
    path: "/form/restaurantAdmimForm/:type",
    name: "restaurantAdmimForm",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/restaurantAdmimForm")
  },
  {
    path: "/profile/restaurantBrandDetails",
    name: "restaurantBrandDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/restaurantBrandDetails")
  },
  {
    path: "/profile/foodCompanyDetails",
    name: "foodCompanyDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/foodCompanyDetails")
  },
  {
    path: "/profile/corporateDetails",
    name: "corporateDetails",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/profile/corporateDetails")
  },
  {
    path: "/tables/invoicesTable",
    name: "invoicesTable",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/tables/invoicesTable")
  },
  {
    path: "/dashboard/financeDashboard",
    name: "financeDashboard",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/financeDashboard")
  },
  {
    path: "/form/userAccessControl",
    name: "userAccessControl",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/userAccessControl")
  },
  {
    path: "/form/configureAccessControl",
    name: "configureAccessControl",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/form/configureAccessControl")
  },
  {
    path: "/roles",
    name: "Roles",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/roles/Roles.vue")
  },
  {
    path: "/permissions",
    name: "Permissions",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/roles/Permissions.vue")
  },
  {
    path: "/role-permissions",
    name: "RolePermissions",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/roles/RolePermission.vue")
  },

  {
    path: "/dashboard/defaultDashboard",
    name: "defaultDashboard",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/dashboard/defaultDashboard")
  }
];
