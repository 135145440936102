<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import helperFile from "../../../helpers/helper";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Restaurant Branch Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Restaurant Branches",
      items: [
        {
          text: "View",
        },
        {
          text: "Restaurant Branches",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "address",
          sortable: true,
        },
        {
          key: "Location",
          sortable: true,
        },
        {
          key: "unique_sw_id",
          sortable: true,
        },
        // {
        //   key: "startTime",
        //   sortable: true,
        // },
        // {
        //   key: "endTime",
        //   sortable: true,
        // },
        {
          key: "avgPreparationTime",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "restCharges",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "createdBy",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readRestaurantData();
    this.substring = helperFile.substringFunction;
    this.checkUserRights();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkUserRights() {
      this.path = this.$route.path;
      this.axios.post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path,
      })
      .then((result) => {
          this.editRight = result.data.data[0].isEdit;
          this.deleteRight = result.data.data[0].isDelete;
      });
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "RestaurantBranch",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "restaurant-branches.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    readRestaurantData() {
      this.axios
        .post(this.$loggedRole+"/viewAllRestaurantBranchs", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },
    deleteRestaurant(reportTaskID) {
      if (reportTaskID > 0) {
        if (confirm("Are you sure delete this Restaurant?")) {
          this.axios
            .post(this.$loggedRole+"/deleteRestaurantBranch", { restBranchID: reportTaskID })
            .then(() => {
              alert("deleted Succesfully");
              location.reload();
            });
        }
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 table-striped">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <router-link
                    :to="{
                      name: 'restaurantBranchForm',
                      params: { id: data.item.restBranchID, type: 'edit' },
                    }"
                    v-if="$can('edit restaurant branch')"
                  >
                    <i
                      class="uil uil-edit-alt"
                      title="Edit"
                      style="font-size: 19px; margin: 2px"
                    ></i
                  ></router-link>
                  <router-link
                    :to="{
                      name: 'restaurantDetails',
                      params: { id: data.item.restBranchID, type: 'edit' },
                    }"
                    ><i
                      class="uil-info-circle"
                      title="View"
                      style="font-size: 19px; margin: 2px"
                    ></i>
                  </router-link>
                  <i
                    class="mdi mdi-delete"
                    title="Delete"
                    style="font-size: 19px; cursor: pointer"
                    @click="deleteRestaurant(data.item.restBranchID)"
                    v-if="$can('delete restaurant branch')"
                  ></i>
                </template>

                <template v-slot:cell(address)="data">
                  <span :title="data.item.address">{{
                    substring(data.item.address)
                  }}</span>
                </template>
                <template v-slot:cell(Location)="data">
                  <span>{{ data.item.branchName }}</span>
                </template>

                <template v-slot:cell(unique_sw_id)="data">
                  <span>{{ data.item.unique_sw_id	}}</span>
                </template>

                <template v-slot:cell(restCharges)="data">
                  <span v-if="data.item.restCharges > 0"
                    >₹{{ data.item.restCharges }}</span
                  >
                  <span v-else> 0 </span>
                </template>

                <template v-slot:cell(avgPreparationTime)="data">
                  <span>{{ data.item.avgPreparationTime }} mins</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
